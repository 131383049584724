import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', value => {
	if (value) {
		return moment(String(value)).isValid() ? moment(String(value)).format('MMM Do YYYY') : 'Invalid Date'
	}
	return value
})

Vue.filter('formatDateTime', value => {
	if (value) {
		return moment(String(value)).isValid() ? moment(String(value)).format('MMM Do YYYY HH:mm') : 'Invalid Date'
	}
	return value
})

Vue.filter('formatTime', value => {
	if (value) {
		return moment(String(value)).isValid() ? moment(String(value)).format('HH:mm') : 'Invalid Date'
	}
	return value
})

Vue.filter('nl2br', value => {
	return value.replace('\n', '<br>')
})


Vue.filter('formatPermissions', item => {
	let perms = []
	_.forEach(item, (value, key) => {
		if (key.indexOf('permission') > -1 && value) {
			perms.push(key.replace('_permission', ''))
		}
	})
	return (perms.length) ? perms.join(', ') : '-'
})

Vue.filter('formatBytes', bytes => {
	if (bytes === 0) return '0 Bytes'
	var k = 1024
	var dm = 2
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	var i = Math.floor(Math.log(bytes) / Math.log(k))
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

Vue.filter('formatVariant', v => {
	v = v.split(':').pop().split('.').pop().toLowerCase()
	let AAs = {
		'ala': 'A',
		'arg': 'R',
		'asn': 'N',
		'asp': 'D',
		'asx': 'B',
		'cys': 'C',
		'glu': 'E',
		'gln': 'Q',
		'glx': 'Z',
		'gly': 'G',
		'his': 'H',
		'ile': 'I',
		'leu': 'L',
		'lys': 'K',
		'met': 'M',
		'phe': 'F',
		'pro': 'P',
		'ser': 'S',
		'thr': 'T',
		'trp': 'W',
		'tyr': 'Y',
		'val': 'V',
		'ter': 'X'
	}
	let reg = v.match(/([a-z]{3})(\d+)([a-z]{3})/)
	if (!reg) {
		return v
	}
	return AAs[reg[1]] + reg[2] + AAs[reg[3]]
})

Vue.filter('initials', v => {
	return v.split(' ').map((n) => n[0].toUpperCase()).join('')
})
