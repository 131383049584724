import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import 'vue-awesome/icons'
import lodash from 'lodash'
import Icon from 'vue-awesome/components/Icon'
import '@/filters/filters.js'
import 'bootstrap/dist/css/bootstrap.css'
import '@/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/plugins/echarts'

Vue.config.productionTip = false
// globally (in your main .js file)
const options = {
	toast: {
		position: SnotifyPosition.rightTop
	}
}


Vue.use(Snotify, options)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(lodash)
Vue.component('v-icon', Icon)


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
