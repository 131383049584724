import { HTTP } from '@/router/http'
import Base64 from '@/mixins/base64'
import { siteTitle } from '@/app_config'
// initial state
const state = {
	current: {
		username: null,
		authdata: null,
		permissions: []
	}

}

// getters
const getters = {
	currentUser: state => state.current
}

// actions
const actions = {
	login ({ commit }, user) {
		return new Promise((resolve, reject) => {
			HTTP.post('/authenticate', user)
				.then(function (res) {
					var userData = res.data

					// var parts = Base64.decode(userData.authdata).split(':')
					var loggedUser = {
						username: userData.login,
						permissions: ['admin'],
						authdata: userData.authdata
					}
					localStorage.setItem(`currentUser${siteTitle}`, JSON.stringify(loggedUser))

					commit('LOGIN', loggedUser)
					resolve(userData)
				})
				.catch(function (err) { reject(err) })
		})
	},
	getCredentials ({ commit }) {
		if (state.current.user_id) {
			return state.current
		} else {
			let user = localStorage.getItem(`currentUser${siteTitle}`)
			if (user) {
				user = JSON.parse(user)
				var parts = Base64.decode(user.authdata).split(':')
				HTTP.defaults.auth = { username: parts[0], password: parts[1] }
				commit('LOGIN', user)
				return true
			} else {
				return false
			}
		}
	},
	checkPermissions (ctx, params) {
		let permissionsToCheck = params.permissions
		let condition = params.condition
		if (condition !== 'all') condition = 'any'
		if (!state.current.permissions) return false
		if (condition === 'any') return state.current.permissions.some(v => permissionsToCheck.includes(v))
		else if (condition === 'all') return _.difference(permissionsToCheck, state.current.permissions).length === 0
		return false
	},
	logout ({ commit }) {
		localStorage.removeItem(`currentUser${siteTitle}`)
		HTTP.defaults.headers.common['authorization'] = ''
		commit('LOGOUT')
	}


}

// mutations
const mutations = {
	SET_USERS (state, users) {
		state.all = users
	},
	LOGIN (state, user) {
		state.current = user
	},
	LOGOUT (state) {
		state.current = {
			username: null,
			authdata: null,
			permissions: null
		}
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
