export default {

	keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

	encode (input) {
		var output = ''
		var chr1; var chr2; var chr3 = ''
		var enc1; var enc2; var enc3; var enc4 = ''
		var i = 0

		do {
			chr1 = input.charCodeAt(i++)
			chr2 = input.charCodeAt(i++)
			chr3 = input.charCodeAt(i++)

			enc1 = chr1 >> 2
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
			enc4 = chr3 & 63

			if (isNaN(chr2)) {
				enc3 = enc4 = 64
			} else if (isNaN(chr3)) {
				enc4 = 64
			}

			output = output +
			this.keyStr.charAt(enc1) +
			this.keyStr.charAt(enc2) +
			this.keyStr.charAt(enc3) +
			this.keyStr.charAt(enc4)
			chr1 = chr2 = chr3 = ''
			enc1 = enc2 = enc3 = enc4 = ''
		} while (i < input.length)

		return output
	},

	decode (input) {
		var output = ''
		var chr1; var chr2; var chr3 = ''
		var enc1; var enc2; var enc3; var enc4 = ''
		var i = 0

		// remove all characters that are not A-Z, a-z, 0-9, +, /, or =
		// var base64test = /[^A-Za-z0-9+/=]/g
		input = input.replace(/[^A-Za-z0-9+/=]/g, '')

		do {
			enc1 = this.keyStr.indexOf(input.charAt(i++))
			enc2 = this.keyStr.indexOf(input.charAt(i++))
			enc3 = this.keyStr.indexOf(input.charAt(i++))
			enc4 = this.keyStr.indexOf(input.charAt(i++))

			chr1 = (enc1 << 2) | (enc2 >> 4)
			chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
			chr3 = ((enc3 & 3) << 6) | enc4

			output = output + String.fromCharCode(chr1)

			if (enc3 !== 64) {
				output = output + String.fromCharCode(chr2)
			}
			if (enc4 !== 64) {
				output = output + String.fromCharCode(chr3)
			}

			chr1 = chr2 = chr3 = ''
			enc1 = enc2 = enc3 = enc4 = ''
		} while (i < input.length)

		return output
	}


}
