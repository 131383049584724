<template>
	<div class = 'navHeader' style="position: relative">
		<b-navbar toggleable="sm" type="dark" variant="primary">
		<b-navbar-brand><img src="../assets/sib_logo_small.gif" class = 'mx-3' /><img src="../assets/logo_header.png" width="94" height="35" alt="Logo Header" class = "mr-3">
		<h4 style="position: absolute; top: 24px; left: 0; display: block; text-align: center; width: 100%; cursor: pointer" @click="goHome">{{siteTitle}}</h4>
	</b-navbar-brand>
	<b-nav-item to="about" class="ml-auto about">About</b-nav-item>
	</b-navbar>
	</div>
</template>

<script>
import Vue from 'vue'
import { siteTitle } from '@/app_config.js'
import { HTTP } from '@/router/http'
import axios from 'axios'
var timeout
const CancelToken = axios.CancelToken
let cancel

export default {
	name: 'navHeader',
	data () {
		return {
			siteTitle: siteTitle,
			searchPatient: ''
		}
	},
	computed: {
		showNav () {
			return (this.$route.path !== '/' && this.$route.path !== '/home')
		}
	},
	methods: {
		closeMenu (item) {
			item.srcElement.parentElement.parentElement.classList.remove('show')
			document.getElementById('siteTitle').classList.remove('show')
			if (document.getElementById('siteTitle__BV_button_') !== null) document.getElementById('siteTitle__BV_button_').setAttribute('aria-expanded', false)
		},
		setProject (project) {
			this.$store.dispatch('setProject', project).then(() => {
				this.$router.go()
			})
		},
		manageAccount () {
			Vue.prototype.$keycloak.accountManagement()
		},
		logout () {
			this.$store.dispatch('logout').then(() => {
				Vue.prototype.$keycloak.logoutFn()
			})
		},
		getPatients () {
			let _this = this
			HTTP.get('/search/patients/' + this.searchPatient, {
				cancelToken: new CancelToken(function executor (c) {
					cancel = c
				})
			}).then(res => {
				_this.patients = res.data
			})
		},
		goToPatient (patient) {
			if (patient.indexOf('RAVE') > -1) {
				patient = patient.substring(patient.indexOf('] ') + 2)
			}
			this.searchPatient = ''
			this.$router.push('/patient/' + patient)
		},
		goHome () {
			if (this.$route.path !== '/') this.$router.push('/')
		}
	},
	watch: {
		searchPatient (n) {
			let ok = n.length > 3
			if ((this.user.permissions.indexOf('group_2') > -1 || this.user.permissions.indexOf('admin') > -1) && !isNaN(n) && n.length) {
				ok = true
			}
			if (ok) {
				if (timeout) {
					clearTimeout(timeout)
				}
				timeout = setTimeout(() => {
					if (cancel) cancel()
					this.getPatients()
				}, 250)
			}
		}
	}
}

</script>
<style scope>

a.router-link-active {
	border: none;
	text-decoration: none;
}

a.nav-link a, li.nav-item a.nav-link {
	margin: 0;
	padding: 5px 10px;
	font-size: 1.1em;
	white-space: nowrap;
}

li.nav-item a.nav-link.dropdown-toggle {
	color: #FFF !important;
}

a.nav-link svg {
	margin-right: 10px;
}
input.search {
	width: 250px !important;
}

li.about > a {
	color: #FFF !important;
}

</style>
