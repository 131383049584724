export const serverURL = (process.env.NODE_ENV === 'development') ? 'https://immucanscdb.vital-it.ch/api/index.php/' : '/api/'

export const siteTitle = `IMMUcan SingleCell RNAseq Database`


export const getHeader = function () {
	const headers = {
		'Accept': 'application/json',
		'Authorization': ''
	}
	return headers
}
