import Vue from 'vue'
import Vuex from 'vuex'
import scrnaseq from './modules/scrnaseq'
import users from './modules/users'


Vue.use(Vuex)


export default new Vuex.Store({
	modules: {
		scrnaseq,
		users
	}
})
