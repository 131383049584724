import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// import Maintenance from '@/components/maintenance/Maintenance'

// import {ServerTable, ClientTable, Event} from 'vue-tables-2';

Vue.use(Router)
// Vue.use(ClientTable);

const router = new Router({
	mode: 'history',
	routes: [
		// {
		// 	path: '*',
		// 	name: 'maintenance',
		// 	component: Maintenance
		// },
		// {
		// 	path: '/*',
		// 	name: 'maintenance',
		// 	component: Maintenance
		// },
		{
			path: '/',
			name: 'ScRNAseq',
			component: () => import(/* webpackChunkName: "scrnaseq" */ '@/components/Table')
		},
		{
			path: '/about',
			name: 'About',
			component: () => import(/* webpackChunkName: "about" */ '@/components/About')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackChunkName: "admin" */ '@/components/Login')
		},
		{
			path: '/admin',
			name: 'admin',
			beforeEnter: requireAuth,
			component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin'),
			meta: { permissions: 'admin', condition: 'any' }
		},
		{
			path: '/:dataset_id',
			name: 'ScRNAseq',
			component: () => import(/* webpackChunkName: "scrnaseq" */ '@/components/Table')
		}

	]
})

function requireAuth (to, from, next) {
	store.dispatch('getCredentials').then(test => {
		if (!test) {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		} else {
			if (to.matched.some(record => record.meta.permissions.length > 0)) {
				store.dispatch('checkPermissions', { permissions: to.meta.permissions, condition: to.meta.condition }).then(res => {
					if (res) {
						next()
					} else {
						next({
							path: '/'
						})
					}
				})
			} else {
				next()
			}
		}
	})
}

export default router
