import { HTTP } from '@/router/http'
// initial state
var loadingGene = ''
var previousLimit = 10000
const state = {
	scrnaseq_datasets: {},
	scrnaseq_dataset_id: null,
	dataset_genes: [],
	scrnaseqGeneComp: {},
	scrnaseqGeneExpr: { headers: [], data: [] },
	scrnaseqGeneDE: { headers: [], data: [] },
	scrnaseq_gene_dist: {
		gene: '',
		data: []
	},
	matrix: [],
	cells: {},
	parameters: {},
	limit: 10000,
	loadingDist: false
}

// getters
const getters = {
	scrnaseq_datasets: state => state.scrnaseq_datasets,
	matrix: state => state.matrix,
	dataset_genes: state => state.dataset_genes,
	scrnaseqGeneComp: state => state.scrnaseqGeneComp,
	scrnaseqGeneExpr: state => state.scrnaseqGeneExpr,
	scrnaseqGeneDE: state => state.scrnaseqGeneDE,
	scrnaseq_gene_dist: state => state.scrnaseq_gene_dist,
	scrnaseq_gene_dist_loading: state => state.loadingDist,
	scrnaseq_cells: state => state.cells,
	scrnaseq_parameters: state => state.parameters,
	scrnaseq_sizelimit: state => state.limit
}

// actions
const actions = {
	getScrnaseqDatasets ({ commit }) {
		return new Promise((resolve, reject) => {
			HTTP.get('/scrnaseq').then(res => {
				commit('SET_SCRNASEQ_DATASETS', res.data)
				resolve(res.data.data.length)
			})
				.catch(function (err) { reject(err) })
		})
	},
	getScrnaseqOneDataset ({ commit, state }, datasetId) {
		if (state.scrnaseq_dataset_id !== datasetId) {
			commit('SET_SCRNASEQ_DATASET_ID', datasetId)
			HTTP.get('/scrnaseq/' + datasetId + '/genes').then(res => {
				commit('SET_SCRNASEQ_DATASET_GENES', res.data)
			})
		}

		return new Promise((resolve, reject) => {
			HTTP.get('/scrnaseq/' + datasetId, { params: { limit: state.limit } }).then(res => {
				commit('SET_SCRNASEQ_UMAP_DATASET', res.data)
				resolve(res.data)
			})
				.catch(function (err) {
					reject(err)
				 })
		})
	},
	getScrnaseqGeneExpression ({ state, commit }, params) {
		return new Promise((resolve, reject) => {
			commit('SET_SCRNASEQ_GENE_EXPR', '')
			HTTP.get('/scrnaseq/' + params.dataset_id + '/gene_expression', { params: { category: params.category, limit: state.limit } }).then(res => {
				commit('SET_SCRNASEQ_GENE_EXPR', res.data)
				resolve(res.data)
			})
				.catch(function (err) { reject(err) })
		})
	},
	getScrnaseqGeneDE ({ state, commit }, params) {
		return new Promise((resolve, reject) => {
			commit('SET_SCRNASEQ_GENE_DE', '')
			HTTP.get('/scrnaseq/' + params.dataset_id + '/gene_de', { params: { category: params.category, limit: state.limit } }).then(res => {
				commit('SET_SCRNASEQ_GENE_DE', res.data)
				resolve(res.data)
			})
				.catch(function (err) { reject(err) })
		})
	},
	getScrnaseqGeneComparison ({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			params.limit = state.limit
			HTTP.get('/scrnaseq/' + state.scrnaseq_dataset_id + '/gene_comparison', { params: params }).then(res => {
				commit('SET_SCRNASEQ_GENE_COMP', { gene1: params.gene1, gene2: params.gene2, expr: res.data })
				resolve(res.data)
			})
				.catch(function (err) { reject(err) })
		})
	},
	getScrnaseqGeneDistribution ({ commit, state }, params) {
		let dataset = (params.dataset === undefined) ? state.scrnaseq_dataset_id : params.dataset
		return new Promise((resolve, reject) => {
			if (loadingGene === params.gene && previousLimit === state.limit) {
				if (state.scrnaseq_gene_dist.gene === params.gene && (params.dataset === undefined || params.dataset === state.scrnaseq_dataset_id)) {
					resolve(state.scrnaseq_gene_dist)
				} else {
					resolve('LOADING')
				}
			} else {
				if (state.loadingDist) {
					resolve('LOADING')
					return
				}
				commit('SET_LOADING_DIST', true)
				loadingGene = params.gene
				previousLimit = state.limit + ''
				if (state.scrnaseq_gene_dist.gene === params.gene && (params.dataset === undefined || params.dataset === state.scrnaseq_dataset_id)) {
					resolve(state.scrnaseq_gene_dist)
				} else {
					HTTP.get('/scrnaseq/' + dataset + '/gene_distribution/' + loadingGene, { params: { limit: state.limit } }).then(res => {
						commit('SET_SCRNASEQ_GENE_DIST', { gene: loadingGene, dist: res.data.split('\n') })
						commit('SET_LOADING_DIST', false)
						resolve(state.scrnaseq_gene_dist)
					})
						.catch(function (err) {
							commit('SET_LOADING_DIST', true)
							reject(err)
						})
				}
			}
		})
	},
	getPatientBarData ({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			HTTP.get('/scrnaseq/' + params.dataset_id + '/patient_data?annotation=' + params.annotation.replace('annotation_', '')).then(res => {
				resolve(res.data)
			}).catch(err => reject(err))
		})
	},
	getScrnaseqParams ({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			HTTP.get('/scrnaseq/' + params.dataset_id + '/parameters').then(res => {
				commit('SET_SCRNASEQ_PARAMETERS', res.data)
				resolve(res.data)
			})
				.catch(function (err) { reject(err) })
		})
	}


}

// mutations
const mutations = {
	SET_SCRNASEQ_DATASETS (state, datasets) {
		state.scrnaseq_datasets = datasets
	},
	SET_SCRNASEQ_DATASET_ID (state, id) {
		state.scrnaseq_dataset_id = id
	},
	SET_SCRNASEQ_DATASET_GENES (state, genes) {
		state.dataset_genes = genes
		state.scrnaseq_gene_dist.gene = ''
		state.scrnaseq_gene_dist.data = []
	},

	SET_SCRNASEQ_UMAP_DATASET (state, dataset) {
		let cells = {}
		let tmpMat = dataset.split('\n')
		let headers = tmpMat.shift().split('\t')
		let matrix = _.map(tmpMat, c => c.split('\t').map(v => isNaN(v) ? v : +v))
		_.forEach(headers, (h, hidx) => {
			if (h.indexOf('annotation') > -1) {
				cells[h] = {}
				_.forEach(matrix, cols => {
					if (cells[h][cols[hidx]] === undefined) cells[h][cols[hidx]] = 0
					cells[h][cols[hidx]]++
				})
			}
		})
		state.cells = cells
		const xIdx = _.findIndex(headers, h => h === 'UMAP_1')
		const yIdx = _.findIndex(headers, h => h === 'UMAP_2')
		matrix = _.map(matrix, m => {
			const y = m.splice(yIdx, 1)[0]
			const x = m.splice(xIdx, 1)[0]
			return [x, y].concat(m)
		})
		let y = headers.splice(yIdx, 1)[0]
		let x = headers.splice(xIdx, 1)[0]
		headers = [x, y].concat(headers)
		state.matrix = { headers: headers, data: matrix }
	},
	SET_SCRNASEQ_GENE_EXPR (state, data) {
		let table = data.split('\n')
		let header = table.shift()
		state.scrnaseqGeneExpr.headers = header.split('\t')
		state.scrnaseqGeneExpr.data = table.map(d => d.split('\t').map(e => (isNaN(e)) ? e : +e))
	},
	SET_SCRNASEQ_GENE_DE (state, data) {
		state.scrnaseqGeneDE.headers = data.headers
		state.scrnaseqGeneDE.data = data.data
	},
	SET_SCRNASEQ_GENE_COMP (state, data) {
		let headers = data.expr.shift().split(',')
		let matrix = _.map(data.expr, c => c.split(',').map(v => isNaN(v) ? v : +v))
		const xIdx = _.findIndex(headers, h => h === data.gene1)
		const yIdx = _.findIndex(headers, h => h === data.gene2)
		matrix = _.map(matrix, m => {
			const y = m.splice(yIdx, 1)[0]
			const x = m.splice(xIdx, 1)[0]
			return [x, y].concat(m)
		})
		let y = headers.splice(yIdx, 1)[0]
		let x = headers.splice(xIdx, 1)[0]
		headers = [x, y].concat(headers)
		state.matrix = { headers: headers, data: matrix }
		state.scrnaseqGeneComp = { gene1: data.gene1, gene2: data.gene2, data: matrix, headers: headers }
	},
	SET_SCRNASEQ_GENE_DIST (state, data) {
		let headers = data.dist.shift().split(',')
		_.forEach(headers, (h, i) => {
			if (h === data.gene) headers[i] = 'value'
		})
		let matrix = []
		_.forEach(data.dist, row => {
			let cell = {}
			let cols = row.split(',')
			for (let i = 0; i < cols.length; i++) {
				cell[headers[i]] = isNaN(cols[i]) ? cols[i] : +cols[i]
			}
			matrix.push(cell)
		})
		state.scrnaseq_gene_dist = { gene: data.gene, data: matrix }
	},
	SET_SCRNASEQ_LIMIT (state, limit) {
		state.limit = limit
	},
	SET_SCRNASEQ_PARAMETERS (state, params) {
		state.parameters = params
	},
	SET_DATASET_H5AD (state, datasetId) {
		let idx = _.findIndex(state.scrnaseq_datasets.data, d => d.unique_id === datasetId)
		if (idx > -1) {
			state.scrnaseq_datasets.data[idx]['h5ad'] = true
		}
	},
	SET_LOADING_DIST (state, loading) {
		state.loadingDist = loading
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
